import Vue from 'vue'
import Vuex from 'vuex'

import aggregator from '@/store/aggregator'
import auth from '@/store/auth'
import messages from '@/store/messages'
import messaging from '@/store/messaging'
import organisation from '@/store/organisation'
import spotlight from '@/store/spotlight'
import stats from '@/store/stats'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        aggregator,
        auth,
        messages,
        messaging,
        organisation,
        spotlight,
        stats,
    },
})
