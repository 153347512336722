import { firebase } from '@/firebase'

const initialState = () => {
    return {
        token: null,
        messages: [],
        unsubscribe: null,
    }
}

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        isPermissioned(state) {
            return !!state.token
        },
        getToken(state) {
            return state.token
        },
    },
    mutations: {
        setToken: (state, token) => {
            state.token = token
        },
        onMessage: (state, payload) => {
            state.messages = [...state.messages, payload]
        },
        subscribe: (state, unsubscribe) => {
            state.unsubscribe = unsubscribe
        },
        reset: state => {
            if (state.unsubscribe) {
                state.unsubscribe()
                state.unsubscribe = null
            }
            state.token = null
            state.messages = []
        },
    },
    actions: {
        initialise: async ({ commit }) => {
            try {
                const messaging = firebase.messaging()
                const token = await messaging.getToken()
                console.log('FCM registration token', token)
                commit('setToken', token)
                const unsubscribe = messaging.onMessage(payload => {
                    console.debug('Received message', payload)
                    commit('onMessage', payload)
                })
                commit('subscribe', unsubscribe)
            } catch (err) {
                console.error(err)
            }
        },
        reset: ({ commit }) => {
            console.log('Resetting messaging...')
            commit('reset')
        },
    },
}
