import { db } from '@/firebase'

const initialState = () => {
    return {
        items: [],
        schemas: [],
    }
}

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        getItem: state => itemId => {
            return state.items.find(item => item.id === itemId)
        },
        getItems: state => {
            return state.items
        },
        getSchemas: state => {
            return state.schemas
        },
    },
    mutations: {
        subscribe: (state, unsubscribe) => {
            state.unsubscribe = unsubscribe
        },
        unsubscribe: state => {
            if (state.unsubscribe) {
                state.unsubscribe()
                state.unsubscribe = null
            }
        },
        addSchema: (state, schema) => {
            state.schemas = [...state.schemas, schema]
        },
        reset: state => {
            state.items = []
            state.schemas = []
        },
        addItem: (state, item) => {
            state.items = [...state.items, { ...item, count: 1 }]
        },
        modifyItem: (state, item) => {
            let index = state.items.findIndex(m => m.id == item.id)
            if (index !== -1) {
                const { count } = state.items[index]
                // See https://vuejs.org/2016/02/06/common-gotchas/#Why-isn%E2%80%99t-the-DOM-updating
                // See https://vuejs.org/v2/guide/list.html#Array-Change-Detection
                state.items.splice(index, 1, { ...item, count: count + 1 })
            }
        },
        removeItem: (state, item) => {
            state.items = [...state.items.filter(m => m.id !== item.id)]
        },
    },
    actions: {
        clear: async ({ commit }) => {
            commit('unsubscribe')
            commit('reset', [])
        },
        initialise: async ({ dispatch, commit, rootState }) => {
            dispatch('clear')
            try {
                const { organisationId } = rootState.auth.user

                console.debug(`Getting spotlight schemas`)
                const schemaSnapshots = await db
                    .collection('spotlight/config/schemas')
                    .get()
                schemaSnapshots.forEach(doc => {
                    commit('addSchema', { id: doc.id, ...doc.data() })
                })

                const collectionRef = db
                    .collection('spotlight/data/items')
                    .where('organisationId', '==', organisationId)
                const unsubscribe = await collectionRef.onSnapshot(snapshot => {
                    snapshot.docChanges().forEach(change => {
                        const item = {
                            id: change.doc.id,
                            ...change.doc.data(),
                        }
                        if (change.type === 'added') {
                            commit('addItem', item)
                        }
                        if (change.type === 'modified') {
                            commit('modifyItem', item)
                        }
                        if (change.type === 'removed') {
                            commit('removeItem', item)
                        }
                    })
                })
                commit('subscribe', unsubscribe)
            } catch (error) {
                console.error(error.message)
            }
        },
        newItem: async (_, item) => {
            await db.collection('spotlight/data/items').add(item)
        },
        updateItem: async (_, { id, data }) => {
            console.debug('spotlight/updateItem', id, data)
            await db
                .collection('spotlight/data/items')
                .doc(id)
                .set(data)
        },
        removeItem: async (_, id) => {
            await db
                .collection('spotlight/data/items')
                .doc(id)
                .delete()
        },
    },
}
