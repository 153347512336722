import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export const options = {
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        options: {
            customProperties: true,
        },
        dark: false,
        themes: {
            light: {},
            dark: {},
        },
    },
}

export default new Vuetify(options)
