import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/messaging'

const config = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    vapidKey: process.env.VUE_APP_VAPID_KEY,
}
const app = firebase.initializeApp(config)

// utils
const auth = app.auth()
const db = app.firestore()
const functions = app.functions('australia-southeast1')
const storage = app.storage()

// Cater for running locally and connecting to emulated resources.
if (location.hostname === 'localhost') {
    console.warn('Using localhost for firestore emulator')
    auth.useEmulator('http://localhost:9099', { disableWarnings: true })
    db.useEmulator('localhost', 8080)
    functions.useEmulator('localhost', 5001)
    storage.useEmulator('localhost', 9199)
}

// export utils/refs
export { firebase, auth, db, functions, storage }
