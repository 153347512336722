import Vue from 'vue'
import { firebase, auth, db, functions } from '@/firebase'

const initialUserState = () => {
    return {
        userId: null,
        email: null,
        name: null,
        employeeId: null,
        organisationId: null,
        roles: [],
    }
}
const initialState = () => {
    return {
        user: initialUserState(),
        busy: false,
        error: null,
        unsubscribe: null,
    }
}

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        isAuthenticated(state) {
            return !!state.user.userId
        },
        isBusy(state) {
            return state.busy
        },
        getError(state) {
            return state.error
        },
        isUserValid: state => {
            return !!state.user.userId
        },
        getUser: state => {
            return state.user
        },
        getEmail: state => {
            return state.user.email
        },
        getRoles: state => {
            return state.user.roles
        },
        hasRoles: state => roles => {
            return roles.every(role => state.user.roles.includes(role))
        },
    },
    mutations: {
        reset: state => {
            if (state.unsubscribe) {
                state.unsubscribe()
                state.unsubscribe = null
            }
            Vue.set(state, 'user', initialUserState())
            state.busy = false
        },
        setUserData: (state, data) => {
            state.user = {
                ...state.user,
                ...data,
            }
        },
        setError: (state, error = null) => {
            state.error = error
        },
        setBusy: (state, busy = true) => {
            state.busy = busy
        },
        userSubscribe: (state, unsubscribe) => {
            state.unsubscribe = unsubscribe
        },
    },
    actions: {
        initialise: async ({ dispatch, commit }) => {
            auth.onAuthStateChanged(async user => {
                console.log('Auth state change')
                if (user) {
                    try {
                        const userId = user.uid
                        console.log(`Authenticated as ${userId}`)
                        const userDocRef = db.collection('users').doc(userId)
                        const doc = await userDocRef.get()
                        if (!doc.exists) {
                            console.log(
                                `Creating user profile for current user`
                            )
                            try {
                                await functions.httpsCallable(
                                    'createUserProfile'
                                )()
                                console.log('User profile created successfully')
                            } catch (err) {
                                throw `Unable to create user profile (${err.message})`
                            }
                        }
                        let firstTime = true
                        console.log('Creating snapshot listener')
                        const unsubscribe = userDocRef.onSnapshot(snapshot => {
                            console.debug('Auth snapshot listener', snapshot)
                            commit('setUserData', {
                                userId,
                                ...snapshot.data(),
                            })
                            if (firstTime) {
                                commit('userSubscribe', unsubscribe)
                                dispatch('signedIn', null, {
                                    root: true,
                                })
                                firstTime = false
                            }
                        })
                    } catch (err) {
                        console.error('Signing out', err)
                        commit('setError', err)
                        dispatch('signOut')
                    }
                } else {
                    console.log('Unauthenticated')
                    commit('reset')
                    dispatch('signedOut', null, { root: true })
                }
            })
        },
        signIn: async ({ commit }, { email, password }) => {
            try {
                commit('setBusy')
                commit('setError', null)
                await auth.signInWithEmailAndPassword(email, password)
            } catch (err) {
                commit('setError', err)
                commit('setBusy', false)
            }
        },
        signInWithGoogle: async ({ commit }) => {
            try {
                // TODO: See https://firebase.google.com/docs/auth/web/google-signin for optional settings //
                commit('setBusy')
                commit('setError', null)
                const provider = new firebase.auth.GoogleAuthProvider()
                await auth.signInWithPopup(provider)
            } catch (err) {
                commit('setError', err)
                commit('setBusy', false)
            }
        },
        signInWithMicrosoft: async ({ commit }) => {
            try {
                commit('setBusy')
                commit('setError', null)
                const provider = new firebase.auth.OAuthProvider(
                    'microsoft.com'
                )
                provider.setCustomParameters({
                    prompt: 'consent',
                })
                await auth.signInWithPopup(provider)
            } catch (err) {
                commit('setError', err)
                commit('setBusy', false)
            }
        },
        signOut: async ({ commit }) => {
            try {
                console.log('Signing out')
                await auth.signOut()
            } catch (err) {
                commit('setError', err)
            }
        },
    },
}
