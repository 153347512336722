import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/signin',
        name: 'signIn',
        component: () =>
            import(/* webpackChunkName: "signin" */ '@/views/SignIn'),
    },
    {
        path: '/main',
        name: 'main',
        component: () => import(/* webpackChunkName: "main" */ '@/views/Main'),
        meta: { requiresValidUser: true, title: 'Menu' },
    },
    {
        path: '/scan',
        name: 'scan',
        component: () => import(/* webpackChunkName: "scan" */ '@/views/Scan'),
        meta: {
            requiresValidUser: true,
            title: 'Scan a QR Code',
            icon: 'close',
        },
    },
    {
        path: '/messages',
        name: 'messages',
        component: () =>
            import(/* webpackChunkName: "messages" */ '@/views/Messages'),
        meta: {
            requiresValidUser: true,
            title: 'Messages',
            icon: 'arrow-left',
        },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () =>
            import(
                /* webpackChunkName: "dashboard" */ '@/views/LeadersDashboard'
            ),
        meta: {
            requiresValidUser: true,
            requiresRoles: ['leader'],
            title: 'Leaders Dashboard',
            icon: 'arrow-left',
        },
    },
    {
        path: '/admin',
        name: 'admin',
        component: () =>
            import(/* webpackChunkName: "admin" */ '@/views/Admin'),
        meta: {
            requiresValidUser: true,
            requiresRoles: ['admin'],
            title: 'Admin',
            icon: 'arrow-left',
        },
    },
    {
        path: '/redirect/:redirectId',
        name: 'redirect',
        component: () =>
            import(/* webpackChunkName: "redirect" */ '@/views/Redirect'),
        props: route => ({ query: { ...route.query }, ...route.params }),
        meta: { requiresValidUser: true },
    },
    {
        path: '/campaign/:campaignId',
        component: () =>
            import(/* webpackChunkName: "campaign" */ '@/views/Campaign'),
        props: route => ({ query: { ...route.query }, ...route.params }),
        meta: { requiresValidUser: true, title: 'Campaign', icon: 'close' },
    },
    {
        path: '*',
        redirect: 'main',
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            if (to.hash) {
                return {
                    selector: to.hash,
                }
            }
            return { x: 0, y: 0 }
        }
    },
})

router.beforeEach((to, from, next) => {
    const isUserValid = store.getters['auth/isUserValid']
    // If the route reqires a role, check that the user has that role.
    const hasRequiredRole =
        !to.meta.requiresRoles ||
        store.getters['auth/hasRoles'](to.meta.requiresRoles)
    if (to.meta.requiresValidUser && !isUserValid) {
        // User is not valid
        // Keep the requested path so that a redirect can be done later.
        sessionStorage.setItem('redirectPath', to.fullPath)
        // Redirect to 'signIn'
        next({ name: 'signIn' })
    } else if (!hasRequiredRole) {
        // If a route requiring a specific is requested, simply redirect to the dashboard.
        console.error("Don't have required role")
        next({ name: 'main' })
    } else {
        next()
    }
})

export default router
