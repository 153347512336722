import { db } from '@/firebase'

const initialState = () => {
    return {
        stats: [],
    }
}

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        getStats: state => {
            return state.stats
        },
        getStatsByRole: state => role => {
            return state.stats.filter(stat => stat.roles.includes(role))
        },
    },
    mutations: {
        subscribe: (state, unsubscribe) => {
            state.unsubscribe = unsubscribe
        },
        unsubscribe: state => {
            if (state.unsubscribe) {
                state.unsubscribe()
                state.unsubscribe = null
            }
        },
        clear: state => {
            state.stats = []
        },
        add: (state, stats) => {
            state.stats = [...state.stats, stats]
        },
        modify: (state, stats) => {
            let index = state.stats.findIndex(s => s.id == stats.id)
            if (index !== -1) {
                // See https://vuejs.org/2016/02/06/common-gotchas/#Why-isn%E2%80%99t-the-DOM-updating
                // See https://vuejs.org/v2/guide/list.html#Array-Change-Detection
                state.stats.splice(index, 1, { ...stats })
            }
        },
        remove: (state, stats) => {
            state.stats = [...state.stats.filter(s => s.id !== stats.id)]
        },
    },
    actions: {
        clear: async ({ commit }) => {
            commit('unsubscribe')
            commit('clear')
        },
        initialise: async ({ dispatch, commit, rootState }) => {
            dispatch('clear')
            const { organisationId } = rootState.auth.user
            console.debug(
                'Getting stats documents ref for organisation',
                organisationId
            )
            const collectionRef = db
                .collection('stats')
                .where('organisationId', '==', organisationId)
            const unsubscribe = await collectionRef.onSnapshot(snapshot => {
                snapshot.docChanges().forEach(change => {
                    const stats = {
                        id: change.doc.id,
                        ...change.doc.data(),
                    }
                    if (change.type === 'added') {
                        commit('add', stats)
                    }
                    if (change.type === 'modified') {
                        commit('modify', stats)
                    }
                    if (change.type === 'removed') {
                        commit('remove', stats)
                    }
                })
            })
            commit('subscribe', unsubscribe)
        },
    },
}
