<template>
    <v-app>
        <v-app-bar dark color="primary" class="darken-1" app v-if="isUserValid">
            <v-btn icon dark plain class="pa-1" to="/main">
                <v-icon v-if="routeDetails.icon"
                    >mdi-{{ routeDetails.icon }}</v-icon
                >
                <v-avatar v-else tile size="36">
                    <v-img :src="organisation.logo" />
                </v-avatar>
            </v-btn>
            <v-toolbar-title>{{ routeDetails.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-chip v-if="environment === 'development'" color="blue" label>
                Development
            </v-chip>
            <v-chip v-if="environment === 'testing'" color="teal" label>
                Testing
            </v-chip>
            <v-chip v-else-if="environment === 'staging'" color="purple" label>
                Staging
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn icon to="/messages">
                <v-icon v-if="areMessagesUnread">mdi-email-alert</v-icon>
                <v-icon v-else>mdi-email</v-icon>
            </v-btn>
            <v-menu left bottom transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon v-if="isUserAdmin">mdi-account-star</v-icon>
                            <v-icon v-else>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                >{{ user.name
                                }}<span v-if="user.employeeId"
                                    >&nbsp;({{ user.employeeId }})</span
                                ></v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                                user.email
                            }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="signOut">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Sign out</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="isUserAdmin" to="admin">
                        <v-list-item-icon>
                            <v-icon>mdi-tune</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                >Content & Communications</v-list-item-title
                            >
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider />

                    <v-list-item @click="toggleTheme">
                        <v-list-item-icon>
                            <v-icon>mdi-invert-colors</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Theme</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            environment: process.env.VUE_APP_ENVIRONMENT,
        }
    },
    methods: {
        toggleTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
        },

        async signOut() {
            try {
                await this.$store.dispatch('auth/signOut')
            } catch (error) {
                console.error(error)
            }
        },
    },
    computed: {
        isUserValid() {
            return this.$store.getters['auth/isUserValid']
        },
        isUserAdmin() {
            return this.$store.getters['auth/hasRoles'](['admin'])
        },
        user() {
            const { name, email, employeeId } = this.$store.getters[
                'auth/getUser'
            ]
            return {
                name,
                email,
                employeeId,
            }
        },
        areMessagesUnread() {
            return this.$store.getters['messages/areMessagesUnread']
        },
        notificationsActive() {
            return this.$store.getters['messaging/isPermissioned']
        },
        organisation() {
            const name = this.$store.getters['organisation/getName']
            const logo = this.$store.getters['organisation/getLogo']
            return {
                name,
                logo,
            }
        },
        routeDetails() {
            const { title = '', icon } = this.$route.meta
            return {
                title,
                icon,
            }
        },
    },
}
</script>

<style lang="sass">
@import "./sass/variables.sass"
</style>
