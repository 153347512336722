import { db } from '@/firebase'

const initialState = () => {
    return {
        messages: [],
    }
}

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        getMessage: state => messageId => {
            return state.messages.find(message => message.id === messageId)
        },
        getMessages: state => {
            return state.messages
        },
        areMessagesUnread: state => {
            return state.messages.some(message => message.state === 'unread')
        },
    },
    mutations: {
        subscribe: (state, unsubscribe) => {
            state.unsubscribe = unsubscribe
        },
        unsubscribe: state => {
            if (state.unsubscribe) {
                state.unsubscribe()
                state.unsubscribe = null
            }
        },
        setMessages: (state, messages) => {
            state.messages = messages
        },
        addMessage: (state, message) => {
            message.state = message.state || 'unread'
            state.messages = [...state.messages, message]
        },
        modifyMessage: (state, message) => {
            console.debug('Modifying message')
            let index = state.messages.findIndex(m => m.id == message.id)
            if (index !== -1) {
                // See https://vuejs.org/2016/02/06/common-gotchas/#Why-isn%E2%80%99t-the-DOM-updating
                // See https://vuejs.org/v2/guide/list.html#Array-Change-Detection
                state.messages.splice(index, 1, { ...message })
            }
        },
        removeMessage: (state, message) => {
            state.messages = [
                ...state.messages.filter(m => m.id !== message.id),
            ]
        },
    },
    actions: {
        clear: async ({ commit }) => {
            commit('unsubscribe')
            commit('setMessages', [])
        },
        setState: async (context, { id, messageState }) => {
            try {
                const messageRef = db.collection('messages').doc(id)
                const message = await messageRef.get()
                if (message.exists) {
                    messageRef.update({ state: messageState })
                } else {
                    throw `Invalid message ID ${id}`
                }
            } catch (err) {
                throw `Unable to set message state for message ID ${id} (${err.message})`
            }
        },
        initialise: async ({ dispatch, commit, rootState }) => {
            dispatch('clear')
            try {
                const { userId } = rootState.auth.user
                console.debug('Getting messages collection ref for ', userId)
                const collectionRef = db
                    .collection('messages')
                    .where('userId', '==', userId)
                const unsubscribe = await collectionRef.onSnapshot(snapshot => {
                    console.debug('Messages snapshot listener', snapshot)
                    snapshot.docChanges().forEach(change => {
                        const message = {
                            id: change.doc.id,
                            ...change.doc.data(),
                        }
                        if (change.type === 'added') {
                            commit('addMessage', message)
                        }
                        if (change.type === 'modified') {
                            commit('modifyMessage', message)
                        }
                        if (change.type === 'removed') {
                            commit('removeMessage', message)
                        }
                    })
                })
                commit('subscribe', unsubscribe)
            } catch (error) {
                console.error(error.message)
            }
        },
    },
}
