import Vuetify from '@/plugins/vuetify'
import { db } from '@/firebase'

// This is the Vuetify default theme.
const defaultTheme = {
    primary: '#1976D2',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
}

const initialState = () => {
    return {
        name: null,
        shortName: null,
        logo: null,
        metaCampaigns: [],
        theme: { ...defaultTheme },
    }
}

const setAttribute = (node, attribute, value) => {
    if (node) {
        node.setAttribute(attribute, value)
    }
}

const switchOrganisation = ({ name, shortName, theme }) => {
    const path = shortName ? `/organisations/${shortName}` : ''
    setAttribute(
        document.querySelector('head link[rel="icon"][sizes="16x16"]'),
        'href',
        `${path}/img/icons/icon-16x16.png`
    )
    setAttribute(
        document.querySelector('head link[rel="icon"][sizes="32x32"]'),
        'href',
        `${path}/img/icons/icon-32x32.png`
    )
    setAttribute(
        document.querySelector('head link[rel="manifest"]'),
        'href',
        `${path}/manifest.json`
    )
    setAttribute(
        document.querySelector('head link[rel="mask-icon"]'),
        'href',
        `${path}/icon.svg`
    )
    setAttribute(
        document.querySelector('head link[rel="mask-icon"]'),
        'color',
        theme.primary
    )
    setAttribute(
        document.querySelector('head link[rel="apple-touch-icon"]'),
        'href',
        `${path}/img/icons/icon-152x152.png`
    )
    setAttribute(
        document.querySelector('head meta[name="theme-color"]'),
        'content',
        theme.primary
    )
    setAttribute(
        document.querySelector('head meta[name="apple-mobile-web-app-title"]'),
        'content',
        name ? `${name} Rhythm` : 'Rhythm'
    )
    setAttribute(
        document.querySelector('head meta[name="msapplication-TileImage"]'),
        'content',
        `${path}/img/icons/icon-144x144.png`
    )
    setAttribute(
        document.querySelector('head meta[name="msapplication-TileColor"]'),
        'content',
        theme.primary
    )
}

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        getName(state) {
            return state.name
        },
        getShortName(state) {
            return state.shortName
        },
        getLogo(state) {
            return state.logo
        },
        getMetaCampaigns(state, _, rootState) {
            const { roles: userRoles } = rootState.auth.user
            return state.metaCampaigns.filter(
                metaCampaign =>
                    !metaCampaign.roles ||
                    metaCampaign.roles.every(role => userRoles.includes(role))
            )
        },
        getTheme(state) {
            return state.theme
        },
    },
    mutations: {
        setOrganisation: (state, { name, shortName, logo, theme }) => {
            state.name = name || null
            state.shortName = shortName || null
            state.logo = logo || null
            state.metaCampaigns = []
            state.theme = theme || { ...defaultTheme }

            // Set the page title.
            document.title = (state.name ? `${state.name} ` : '') + 'Rhythm'
            // Set other organisation specific nodes accordingly.
            switchOrganisation(state)
        },
        addMetaCampaign: (state, metaCampaign) => {
            state.metaCampaigns = [...state.metaCampaigns, metaCampaign]
        },
        setTheme: (state, theme) => {
            Vuetify.framework.theme.themes.light = { ...theme }
            Vuetify.framework.theme.themes.dark = { ...theme }
        },
        resetTheme: () => {
            Vuetify.framework.theme.themes.light = defaultTheme
            Vuetify.framework.theme.themes.dark = defaultTheme
        },
    },
    actions: {
        reset: async ({ commit }) => {
            commit('setOrganisation', initialState())
            commit('resetTheme')
        },
        initialise: async ({ dispatch, commit, rootState }) => {
            dispatch('reset')
            try {
                const { organisationId } = rootState.auth.user
                const organisationRef = db
                    .collection('_global/config/organisations')
                    .doc(organisationId)
                const organisation = await organisationRef.get()
                if (organisation.exists) {
                    const data = organisation.data()
                    commit('setOrganisation', data)
                    commit('setTheme', data.theme)
                    dispatch('getMetaCampaigns', data.metaCampaignIds)
                } else {
                    console.warn('Unable to find organisation', organisationId)
                }
            } catch (err) {
                console.error(err.message)
            }
        },
        getMetaCampaigns: async ({ commit }, metaCampaignIds) => {
            for (let metaCampaignId of metaCampaignIds || []) {
                try {
                    const metaCampaign = await db
                        .collection('metaCampaigns')
                        .doc(metaCampaignId)
                        .get()
                    if (metaCampaign.exists) {
                        // If there are no roles restrictions or the user has all the valid roles, add the metaCampaign.
                        commit('addMetaCampaign', {
                            id: metaCampaignId,
                            ...metaCampaign.data(),
                        })
                    } else {
                        console.warn('Invalid meta campaign ID', metaCampaignId)
                    }
                } catch (err) {
                    console.error(
                        `Unable to get meta campaign ${metaCampaignId}`,
                        err.message
                    )
                }
            }
        },
    },
}
