export default {
    namespaced: false,
    state: {
        router: null,
    },
    getters: {},
    mutations: {
        setRouter: (state, router) => {
            state.router = router
        },
    },
    actions: {
        initialise: ({ dispatch, commit }, router) => {
            commit('setRouter', router)
            dispatch('auth/initialise')
        },
        signedIn: ({ dispatch, state }) => {
            dispatch('messaging/initialise', { root: true })
            dispatch('messages/initialise', { root: true })
            dispatch('spotlight/initialise', { root: true })
            dispatch('organisation/initialise', { root: true })
            dispatch('stats/initialise', { root: true })
            const redirectPath = sessionStorage.getItem('redirectPath') || {
                name: 'main',
            }
            console.debug('redirecting to', redirectPath)
            state.router.push(redirectPath).catch(() => {})
            sessionStorage.removeItem('redirectPath')
        },
        signedOut: ({ dispatch, state }) => {
            state.router.push({ name: 'signIn' }).catch(() => {})
            dispatch('messaging/reset', { root: true })
            dispatch('messages/clear', { root: true })
            dispatch('spotlight/clear', { root: true })
            dispatch('organisation/reset', { root: true })
            dispatch('stats/clear', { root: true })
        },
    },
}
