import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import VJsf from '@koumoul/vjsf/lib/VJsf.js'
import '@koumoul/vjsf/lib/VJsf.css'
import '@koumoul/vjsf/lib/deps/third-party.js'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

store.dispatch('initialise', router)

Vue.component('VJsf', VJsf)

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')
